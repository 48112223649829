/* Manage users CSS files */ 

:root {

    --primary-color: #1A3D6B;
    --secondary-color:#0088CB;
  
  }

  .link {
    text-decoration:none;
    
  }


  h1, h2, h3, h4, h5, h6 {
    color: var(--primary-color);
    
  }

  .important-info-text {
    color: var(--secondary-color);
  }

  .confirm-button {
    background-color: var(--primary-color);
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    color: white;
    margin: 10px 0;
  }

  .secondary-confirm-button {
    background-color: var(--secondary-color);
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    color: white;
    margin: 10px 0;
  }

  .cancel-button {
    background-color: lightgrey;
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    color: black;
    margin: 10px 0;
  }

  /*-------------------------------*/

  .error-text {
    color: red;
    background-color: rgba(236, 236, 236, 0.733);
    border-radius: 10px;
    border: solid 1px red;
    padding: 5px;
    margin: 10px auto;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    width: 90%;
  }

  .success-text {

    color: green;
    background-color: rgba(236, 236, 236, 0.733);
    border-radius: 10px;
    border: solid 1px green;
    padding: 5px;
    margin: 10px auto;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    width: 90%;

  }

  /*--------------------------------
            Back Button 
    -------------------------------*/

    
    

    .navigate-back-button {

        background-color: var(--primary-color);
        color: white;
        border-radius: 10px;
        width: 100px;
        height: 30px;
        border: none;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    }

.create-company-text-container {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0;
    
}

.create-company-form-container {

    
    padding: 5px 0;
    width: 95%;
    overflow-y: scroll;
    border-radius: 20px;
    margin: 0 auto;
}

.create-company-form-body-container {

    display: flex;
    flex-direction: column;
    background-color: rgb(233, 233, 233);
    border-radius: 20px;
    padding: 30px 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    
}



.create-company-form-body-row-container {

    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    flex-direction: row;
    padding: 0 5%;
}

.create-company-form-body-row-password-container {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    padding: 2% 5%  0 ;

}

.create-company-form-input {

    width: 250px;
    border: solid 1px var(--primary-color);
    border-radius: 10px;
}

.create-company-address-form-input {
    width: 700px;
    border: solid 1px var(--primary-color);
    border-radius: 10px;
}

.create-company-form-text-container{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 10px 0;

}
.create-company-form-button-container {

    
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 30px 0;
}

.create-company-form-create-button {

    height: 40px;
    width: 150px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    background-color: var(--primary-color);
    color: white;

}

.create-company-form-cancel-button {

    height: 35px;
    border-radius: 10px;
    width: 150px;
    border: none;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */

}

/* ----------------------------------------------------
                Create user
----------------------------------------------------- */

/* User Roles */

.create-user-roles-form-body-row-container {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    padding: 2% 5%  0 ;
    

} 

.create-user-roles-priviledges-detail-main-container {
    
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 200px;
    overflow-y: scroll;
    
}


.create-user-roles-priviledges-detail-container {
    background-color: white;
    padding: 0 10px;
    border-radius: 15px;
}
   

.create-user-roles-priviledges-detail-container h4 {
    color: var(--secondary-color);
}



/*--------------------------------------------------------
                Manage Users - Assign user 
-----------------------------------------------------*/

/* Assign user checklist checkbox*/

.assign-user-checkbox-container {

    border: 1px solid grey;
    border-radius: 5px;
    max-height: 70px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
    justify-content:space-evenly;
   
}

.assign-user-checkbox-item {
    
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 20px;
    margin: 5px 0;
}



.select-multiple {
    height: 60px; /* Adjust the height as needed */
    overflow-y: auto;
    padding: 5px 5px;
    width: 150px;
    border-radius: 10px;

}

.assign-user-assign-icon {
    color: var(--primary-color);
    width: 25px;
    height: 25px;
}

.assign-user-assign-icon:hover {
    color: var(--secondary-color);
}

.assign-user-button-container-container {
    padding: 20px 10px ;
}

.assign-user-button {

    background-color: lightgrey;
    color: black;
    border: none;
    border-radius: 5px;
    width: 80px;
    height: 30px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
}

/*----------------------
        Table 
----------------------*/


  .view-selected-user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .view-selected-user-table th,
  .view-selected-user-table td {
  
    min-width: 50px;
    max-width: 300px;
    padding: 8px;
    text-align: center;
  
  }
  
  .view-selected-user-table th {
    background-color:#23406636;
    color: black;
    font-weight: 500;
    color: #1A3D6B;
  }
  
  .view-selected-user-table tbody tr:hover {
  
    background-color: #f5f5f5;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
    
  }
  
  /**/
  .view-selected-user-table tbody tr:nth-child(even) td {
    
    background-color: #f0f0f0; /* Light grey background */
  }

  .view-user-assigned-checklist-container {
    background-color: lightgray;
    width: 100%;
    height: 100px;
    overflow: scroll;
    border-radius: 10px;
  }

  .view-users-unassign-checklist-main-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Highest z-index */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 189, 189, 0.068); /* Transparent background */
  }

  .view-users-unassign-checklist-container {
    background-color: white;
    border-radius: 25px;
    height: 250px;
    width: 600px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.274); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .view-users-unassign-icon {
    color: red;
    width: 50px;
    height: 50px;
  }

  .unassign-red-text {
    color: red;
  }
  

  .view-user-assigned-list-container {
    
    width: 90%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .view-user-assigned-list-container {
    width: 90%;
  }
  

  .view-user-assigned-list {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    border: 1px solid var(--primary-color);
    margin: 2px 0;
    padding: 0 2px ;
    border-radius: 5px ;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    background-color: white;
  }

  /* --------------------------------------------------
                        Assign Users
  -----------------------------------------------------*/

  .view-checklist-component-container {
  
    width: 90%;
    height: 100%;
    overflow: none;
    margin: auto;
    padding:  0 5% 2% 0;
    
    
  
  }
  
  .view-checklist-table-container {
    
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 1% 0;
    
    
  }
  
  .view-checklist-table {
    width: 100%;
    border-collapse: collapse;
    
    
    
  }
  
  .view-checklist-table th,
  .view-checklist-table td {
  
    min-width: 100px;
    max-width: 400px;
    padding: 8px;
    text-align: center;
  
  }
  
  .view-checklist-table th {
    background-color:#23406636;
    color: black;
    font-weight: 500;
    color: #1A3D6B;
  }
  
  .view-checklist-table tbody tr:hover {
  
    background-color: #f5f5f5;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
    
  }
  
  /**/
  .view-checklist-table tbody tr:nth-child(even) td {
    
    background-color: #f0f0f0; /* Light grey background */
  }
  
  .view-checklist-checklist-table-container {
    width: 100%;
  }

  .view-checklist-table th:nth-child(7),
.view-checklist-table td:nth-child(7) {
    width: 200px;
    max-width: 200px;
}

.view-checklist-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Add this line */
}

.view-checklist-table th,
.view-checklist-table td {
    min-width: 100px;
    max-width: 400px;
    padding: 8px;
    text-align: center;
    overflow: hidden; /* Add this line */
    text-overflow: ellipsis; /* Add this line */
    white-space: nowrap; /* Add this line */
}

/* Add this new rule */
.view-checklist-table th:nth-child(7),
.view-checklist-table td:nth-child(7) {
    width: 200px;
    max-width: 200px;
}

.view-checklist-table th {
    background-color:#23406636;
    color: black;
    font-weight: 500;
    color: #1A3D6B;
}

.view-checklist-table tbody tr:hover {
    background-color: #f5f5f5;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
}

.view-checklist-table tbody tr:nth-child(even) td {
    background-color: #f0f0f0; /* Light grey background */
}

.view-checklist-checklist-table-container {
    display: flex;
    width: 90%;
    overflow-x: auto; /* Add this line */
}

/*---------------------------------------------
          Component - Manage Users 
-------------------------------------------*/

.manage-users-component-container {
    
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  justify-content: space-evenly;
 
 
}

.manage-users-header-component-container {
    
 
 
}

.manage-users-company-main-container {
 
  margin: 20px 0;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
}

.manage-users-feature-main-container {
  
  display: flex;
  flex-direction: row;
  width: 95%;
  height: 250px;
  margin: 0 auto;
  
  align-items: center;

}

.manage-users-text-container {

  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
  padding: 5px 0;


}


.manage-users-feature-container {

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgb(238, 238, 238);
  width: 190px;
  height: 220px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid black;
  padding: 0 2%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
  margin-right: 40px;
}


/*-----------------------------------------------------
              Component - Add Department
------------------------------------------------------*/

.manage-users-add-department-main-body-container {
  
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  padding: 2% 0;
}

.manage-users-add-department-main-body-section-container {
  display: flex;
  
  width: 100%;
  justify-content: space-between;
}

.manage-users-add-department-main-body-company-name-add-department-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 45%;
 
}

.manage-users-add-department-main-body-company-name-input-button-field-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
  padding: 1% 0;
}

.manage-users-add-department-main-body-company-name-input {
  border: 1px solid var(--primary-color);
}

.secondary-color-heading {
  color: var(--secondary-color);
}

/*--------------- Edit user Toaster -------------*/

.edit-user-toaster-input {
  border: 1px solid var(--primary-color);
}

.edit-user-password-delete-button-icon-icon {
  color: red;
  margin: auto;
}

.edit-user-taoaster-user-information-submit-button-container {
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35%;
  margin: 0 auto;
}
.edit-user-taoaster-user-information-submit-paragraph-container p {
  font-style: italic;
}

.profile-user-password-structure-container {

  display: flex;
  align-items: center;
  width: 50%;

}

.profile-user-password-structure-paragraph {
  font-style: italic;
  color: var(--secondary-color);
}

.dropdown-container {
 
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;

}

.dropdown-content {
  
  display: flex;
  background-color: lightgrey;
  min-width: 100px;
  max-width: 500px;
  height: 200px;
  overflow-y: scroll;
  
}

.dropdown-content ul{
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5px;
  margin: 3px auto;
  min-width: none;
  align-items: center;
}

.dropdown-content-list {
  background-color: white;
  margin: 2px auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 5px ;
}

/*-----------------------------------
            Department 
----------------------------------*/

/** Department - add department */

.add-department-button-text-container{
  
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
}

.add-department-button-container {
  display: flex;
  flex-direction: row;
  width: 20%;
  justify-content: space-between;
}


.create-checklist-component-container {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
  margin: auto;
  overflow-y: scroll;
  
 
  
  

  } 