/* CSS file for form checklist 

- Create Checklists *
*/

:root {

  --primary-color: #1A3D6B;
  --secondary-color:#0088CB;

}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  
}

body {

  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}
.confirm-button-response {
  background-color: var(--secondary-color);
  height: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  color: white;
  margin: 10px 0;

}

.confirm-button {
  background-color: var(--primary-color);
  height: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  color: white;
  margin: 10px 0;
}

.reject-button {
  background-color: red;
  height: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  color: white;
  margin: 10px 0;
}

.cancel-button {
  background-color: lightgray;
  height: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  color: black;
  margin: 10px 0;
}
 

.error-text-container {
  
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0;
 
  
}

.error-text {
  color: red;
  background-color: rgba(236, 236, 236, 0.733);
  border-radius: 10px;
  border: solid 1px red;
  padding: 5px;
  margin: 10px auto;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  width: 90%;
}

.secondary-color-paragraph {
  color: var(--secondary-color);
}

.confidential {
  display: none;
}

.loading-spinner-container {
  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
  
}

.loading-spinner-assign-checklist-container {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;


}

.checklist-question-toaster-date {

  border: 1px solid var(--primary-color);

}





/**/

.navigate-back-button-main-container {

  width: 80%;
  margin: 0 auto;
  padding: 10px 0 ;
  display: flex;
  justify-content: flex-end;
}



.navigate-back-button {

  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  width: 100px;
  height: 30px;
  border: none;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
}

form {

  width: 100%;
}

input {
  border-radius: 5px;
  border: none;
  height: 25px;
}

.from-name-input {

  border: 1px solid var(--primary-color);
  width: 300px;
  border-radius: 5px;
  margin-bottom: 5%;
}

/*--------------------------------------
          Checklist Managememt 
  -----------------------------------*/

  

  .link {
    text-decoration: none;
  }

  .checklist-component-container {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    justify-content: space-evenly;
   
    

  }

  .checklist-text-container {

    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding: 2% 0;

  }

  .checklist-feature-main-container {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 95%;
    height: 300px;
    margin: 0 auto;

  }

  /* Tablets (Portrait) */
@media (min-width: 600px) and (max-width: 800px) {

  .link {
    text-decoration: none;
  }

  .application-feature-container{
    margin: 10px ;
  }
  .checklist-component-container {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    justify-content: space-evenly;
  
    
  }

  .checklist-text-container {

    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding: 2% 0;

  }

  .checklist-feature-main-container {

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    height: 80%;
    overflow-x: scroll;
    margin: 5px auto;
  
  }

}

  /*---------------------------------------------
              Create Checklist 
    ---------------------------------------------*/

    .create-checklist-component-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95%;
    margin: auto;
    overflow-y: scroll;
    
   
    
    

    }

    .create-checklist-text-container {

      display: flex;
      flex-direction: row;
      width: 95%;
      margin: 0 auto;
      
      
    }

    .create-checklist-text {
      width: 80%;
    }

    .form-container{

    
      display: flex;
      flex-direction: row;
      width: 95%;
      margin: 0 auto;
      overflow-y: auto; /* Trigger scrollbar only when necessary */
      
    }

    /* Hide the scrollbar for WebKit browsers */
    .form-container::-webkit-scrollbar {

      display: none;

}

    .form-body-container {

      
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0 auto;
      justify-content: space-between;
      
    }

    .form-element-container {

     
    }

    .form-body-left-container {
      background-color: white;
      padding: 2% 0;
      width: 60%;
      
    }

    .form-label-container {

      padding: 1% 0;

    }

    /*----------------------------------------------------
                    Custom Checklist Category
    ------------------------------------------------------*/
    .create-checklist-custom-checklist-category-component-main-container {
      background-color: green;
      display: flex;
      flex-direction: column;
      width: 95%;

    }

    .create-checklist-custom-checklist-category-input-container {
      background-color: pink; 
      display: flex;
      padding: 10px 0;
      align-items: center;
      justify-content: space-between;
      width: 80%;
    }

    .create-checklist-custom-checklist-category-responses-container {
      background-color: red;
      display: flex;
      flex-direction: row;
      width: 90%;
      padding: 0 5%;
      justify-content: space-between;
      align-items: center;
      margin: 5px auto;
    }

    .create-checklist-custom-checklist-category-buttons-responses-container {
      background-color: lime;
      display: flex;
      width: 20%;
      justify-content: space-evenly;
      
    }

    .create-checklist-custom-checklist-category-buttons-responses-icon {
      width: 20px;
      height: 20px;
      color: var(--primary-color);

    }

    .create-checklist-custom-checklist-category-buttons-responses-icon:hover {
      color: var(--secondary-color);
    }

    .create-checklist-custom-checklist-category-input {

    }
    /*-------------------------------------------------------
              Checklist form renders saved categories 
    ----------------------------------------------------------*/
    .create-checklist-form-renders-predefined-saved-categories-container {
      background-color: maroon;
      display: flex;
      width: 95%;
      flex-direction: column;

    }

    .create-checklist-form-renders-predefined-saved-header-categories-container {
      background-color: lightblue;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    .create-checklist-form-renders-predefined-saved-options-categories-main-container {
      background-color: yellow;
      display: flex;
      flex-direction: column;
    }

    .create-checklist-form-renders-predefined-saved-options-categories-container {
      display: flex;
      flex-direction: column;
      background-color: white;
      border: 1px solid var(--primary-color);
      margin: 5px auto;
      border-radius: 15px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
      width: 80%;
      padding: 0 5%;
    }

    .create-checklist-form-renders-predefined-saved-header-categories-icons-container {
      display: flex;
      width: 20%;
      justify-content: space-between;
    }
    /*-----------------------------------------------
                      Custom Section 
      ----------------------------------------------*/

      .create-checklist-add-Custom-section {
        background-color: var(--primary-color);
        color: white;
        border: none;
        border-radius: 5px ;
        height: 30px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */

      }

    /*----------------------------------------------------
                  Form fields Section 
      ---------------------------------------------------------*/

      /* form body - right side */
    .form-body-right-container {
      width: 35%;
      display: flex;
      flex-direction: column;
    }

    .form-fields-container {
      display: flex;
      flex-direction: column;
      height: 200px;
      overflow-y: scroll;
      background-color: rgb(235, 235, 235);
      padding: 0 2%;
      border-radius: 20px;
    }

    /*.form-fields-container::-webkit-scrollbar {
      display: flex;
      flex-direction: column;
      height: 300px;
      overflow-y: auto;
      background-color: #0088CB;
    
    } */

    .form-fields-text-container {

      
      margin: 2% 0;
      
    }

    /* buttons - sub menu buttons */

    .predefined-form-section-button {

      background-color: var(--primary-color);
      display: flex;
      flex-direction: row;
      width: 80%;
      justify-content: space-between;
      border-radius: 5px;
      border: none;
      align-items: center;
      color: white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    }

    .category-input-button-container {
      align-items: center;
    }

    .predefined-form-section-button-predefined {

      background-color: var(--secondary-color);
      display: flex;
      flex-direction: row;
      width: 80%;
      justify-content: space-between;
      border-radius: 5px;
      border: none;
      align-items: center;
      color: white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */

    }

    .predefined-form-section-icon {
      width: 25px;
      height: 25px;
      color: white;
    }

    /*Custom field Section*/

    .add-form-field-section-container {

      display: flex;
      flex-direction: column;
      width: 80%;
      background-color: white;
      padding: 2% 1%;
      border-radius: 10px;
    }

    .custom-field-input {
      border: solid 1px var(--primary-color);
    }

    /**/
    
    .custom-form-field-container {

      display: flex;
      flex-direction: row;

    }

    .custom-field-button {
      background-color: var(--primary-color);
      color: white;
      border: none;
      border-radius: 5px;
      width: 150px;
      height: 25px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    }

    /* buttons - add form section button*/
    
    .form-section-button-container {

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0 5%;
      width: 260px;
      height: 30px;
      border: none;
      border-radius: 5px;
      background-color: white;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
      
    }

    .buttons-containers {
      padding: 2% 0;
    }

    .field-button-container {

      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 95%;
      height: 70%;
      margin: auto;
      align-items: center;
      
    }

    .section-button-icon-text {

      display: flex;
      text-align: center;

    }

    .section-button-icon{
      width: 20px;
      height: 20px;
    }

    /**/

    /* Mobile Device */



    .form-right-mobile-device-container {
      background-color: white;
      
    }

    .mobile-device-container {
      
      display: flex;
      width: 100%;
      margin: 15% 0;
    }

    .mobile-device {

      display: flex;
      flex-direction: column;
      border: 10px solid black;
      border-radius: 30px;
      height: 500px;
      width: 80%;
      margin: 1% auto;
      background-color: white;
      padding: 0 3%;
      overflow-y: scroll;
      
      
    }

    /*.mobile-device::-webkit-scrollbar {
      
      overflow-y: auto;
    
    } */

    .mobile-device-screen {
      padding: 5%;
      
    }

    .mobile-form-section-input {

      border: 1px solid var(--primary-color);
    }

    /* --------------------------------------
                    Form Section
      --------------------------------------*/

    .form-sections-rendering-container {

      background-color: var(--secondary-color);
      width: 95%;
      padding: 2% 2%;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      
    }

    .section-container {
      
      width: 50%;
      border-radius: 5px;
      margin: 1% 0;
    }

    .section-container {
      display: flex;
      flex-direction: column;
      width: 80%;
      background-color: white;
      padding: 0 5%;
      border-radius: 10px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
      margin: 3% 0;

    }

    /*----------------------------------------------------
                    Create Checklist Section rendering
    ------------------------------------------------------*/

    .create-checklist-question-section-input-button-container {
      
      width: 100%;
      justify-content: space-between;

    }

    .create-checklist-question-section-button-container {
      padding: 10px 0;
    }

    .create-checklist-section-rendering-container {

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 2% 0;
      
      
    }

    .create-checklist-section-rendering-question-name-container {

      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      
    }

    .create-checklist-section-rendering-question-name-icon-container {
      display: flex;
      padding: 5px 0;
    }

    .create-checklist-section-rendering-question-name-icon {
      color: var(--primary-color);
      width: 20px;
      height: 20px;
      padding: 0 2px;
    }

    .create-checklist-section-rendering-question-type-container {

      
      border: 1px solid var(--primary-color);
      border-radius: 15px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
      width: 100%;
      padding: 2% 2%;

    }

    .question-type-radio-option-name {
      list-style:circle;
    }

    .create-checklist-section-rendering-question-type-header-container {
      
    }


    .create-checklsit-section-rendering-question-type-icon {
      color: var(--secondary-color);
      display: flex;
      width: 25px;
      height: 25px;
      padding: 0 15px 0 0;
    }

    .create-checklist-section-rendering-question-type-body-container {
      
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0 auto;
    }

    .create-checklist-section-rendering-question-type-main-options-container{
      background-color: white;
      border-radius: 15px;
      display: flex;
      width: 100%;
    }
    

    .question-type-edit-options {
      width: 100%;
    }

    .create-checklist-section-rendering-question-type-options-container {
      
      display: flex;
      flex-direction: column;
      width: 95%;
      
    }

    /*Option*/
    .question-type-options-main-container {
      
      
    }

    .question-type-options-container {
      border: 1px solid var(--primary-color);
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      padding: 2px 2px;
      border-radius: 10px;
      align-items: center;
      margin: 5px 0;
    }

    .create-checklist-formsections-rendering-predefined-form-column {
      /*background-color: pink; */
      display: flex;
      padding: 10px 0;
    }

    .create-checklist-formsections-rendering-predefined-form-column-question-name-input {
      border: none;
    
    }

    .create-checklist-formsections-rendering-predefined-form-column-question-type-input {
      border: none;
      display: none;
    }

    /* ------------------------------------------------

    ----------------------------------------------------*/

    /* Form description */
    .section-description-container {

      border-top: 1px solid var(--primary-color)

    }

    .section-description-text-container p{

      color: green;
      font-size: 13px;

    }

    .section-input-container {
      padding: 5% 0;
      
    }

    .section-add-remove-button-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

    }

    .predefined-question-input {
      
      background-color: transparent;
      color: black;
      display: none;
    }

    /* checkbox */

    .form-section-checkbox-container {

      display: flex;
      flex-direction: row;
      
      align-items: center;
      justify-content: space-evenly;
      font-size: 13px;
      
    }

    .section-button-container {

      
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 30%;
      align-items: center;
    }
    
    /**/

    .active-form-section {
      background-color: rgb(233, 233, 233);
      transition: background-color 0.5s ease, transform 0.5s ease; /* Smooth transition for background color and scale */
      transform: scale(1.02); /* Scale the element by 20% on hover */
      box-shadow: 0 3px 5px var(--primary-color); /* Adjust the values as needed */
    }

    .form-section-minimize-button {
      background-color: lightgray;
      border: none;
      border-radius: 5px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add a drop shadow */
    }
    /*-------------------------------------------
                Radio field keybaord 
      ------------------------------------------*/

      .section-radio-field-container {

        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        height: 30px;
        align-items: center;
        margin: 3% 0 ;
      }

      /* */
      .section-radio-add-remove-button-container {

        
        display: flex;
        flex-direction: row;
        width: 15%;
        justify-content: space-between;
        padding: 0 2%;
        align-items: center;
      }

      /**/
      .section-radio-add-remove-button {

        width: 20px;
        height: 20px;
        
      }

      .dull {

        color: lightgrey;
      }

      .form-section-text-area {
        border-radius: 10px;
        max-width: 100%;
        min-height: 50%;
      }

      .radio-input-question {
        border-radius: 1px solid var(--primary-color);
        background-color: white;
      }

      
      /*---------------------------------------------------
          Create and clear form button container
        --------------------------------------------------*/

        .create-clear-form-button-containers {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          padding: 2% 0;
          
          width: 50%;
        }

        .create-form-button {
          background-color: var(--primary-color);
          color: white;
          border: none;
          border-radius: 5px;
          height: 30px;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
          transition: transform 0.3s ease; /* Adding transition for transform */
        }

        .clear-form-button {
          background-color: lightgrey;
          color: black;
          border: none;
          border-radius: 5px;
          height: 30px;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
          transition: all 0.3s ease-in-out;
          
        }

        .create-form-button.grow  {
          transform: scale(1.1); /* Increase size when clicked */
      }

      .clear-form-button.grow {

        transform: scale(1.1); /* Increase size when clicked */

      }

/*------------------------------------------------
                  Fill In
  ------------------------------------------------*/

  .fillin-checklist-submittion-icon:hover {

    color: var(--secondary-color);


  }
  .view-checklist-component-container {
  
    width: 90%;
    height: 100%;
    overflow: none;
    margin: auto;
    
  
  }
  
  
  
  .view-fillin--checklist-table-container {
    
    display: flex;
    flex-direction: column;
    overflow-y:scroll;
    width: 85%;
    margin: 0 auto;
    align-items: center;
  }
  
  .view-fillin-checklist-table {
    width: 100%;
    border-collapse: collapse;
    
    
  }
  
  .view-fillin-checklist-table th,
  .view-fillin-checklist-table td {
  
    width: 300px;
    padding: 8px;
    text-align: center;
    
  
  }
  
  .view-fillin-checklist-table th {
    background-color:#23406636;
    color: black;
    font-weight: 500;
    color: #1A3D6B;
  }
  
  .view-fillin-checklist-table tbody tr:hover {
  
    background-color: #f5f5f5;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    cursor: pointer;
    
  }
  
  /**/
  .view-fillin-checklist-table tbody tr:nth-child(even) td {
    
    background-color: #f0f0f0; /* Light grey background */
  }

/*------------------------------------------------------
              Vieww Checklist
-----------------------------------------------------*/

.view-checklist-submition-icon:hover {
  color: var(--secondary-color);
}

.delete-checklist-submition-icon:hover {
  color: red;
}

.manage-users-checklist-submition-icon:hover {
  color: var(--secondary-color);
}

.view-checklist-component-container {
  
  width: 90%;
  height: 100%;
  overflow: none;
  margin: auto;
  padding:  0 5% 2% 0;
  

}




.view-checklist-table-container {
  
  display: flex;
  flex-direction: column;
  
  width: 90%;
  margin: 0 auto;
  padding: 1% 0;
  
}

.view-checklist-table {
  width: 100%;
  border-collapse: collapse;
}

.view-checklist-table th,
.view-checklist-table td {

  min-width: 80px;
  max-width: 100px;
  padding: 8px;
  text-align: center;

}

.view-checklist-table th {
  background-color:#23406636;
  color: black;
  font-weight: 500;
  color: #1A3D6B;
}

.view-checklist-table tbody tr:hover {

  background-color: #f5f5f5;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
  
}

/**/
.view-checklist-table tbody tr:nth-child(even) td {
  
  background-color: #f0f0f0; /* Light grey background */
}

/* View checklist submission data */

.view-data-checklist-table-container {
  
  display: flex;
  flex-direction: column;
  padding: 2%;
  overflow-y: none;
  
  
}

.view-data-checklist-table-container {
  
  overflow: none;
  display: flex;
  width: 90%;
 
  margin: 0 auto;
  
 
}

.view-checklist-checklist-data-container {
  display: flex;
  
  width: 100%;
}

.view-checklist-submission-table-container {
  display: flex;
  
  overflow: scroll;
  width: 100%;
}

.view-data-checklist-table {
  
  width: 100%;
  border-collapse: collapse;
}

.view-data-checklist-table th,
.view-data-checklist-table td {

  min-width: 150px;
  max-width: 600px;
  padding: 10px;
  text-align: center;

}

.view-data-checklist-table th {
  
  background-color:#1a3d6b6c;
  color: black;
}

.view-data-checklist-table tbody tr:hover {
  background-color: #f5f5f5;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor:pointer;
}

/**/
.view-data-checklist-table tbody tr:nth-child(even) td {
  
  background-color: #f0f0f0; /* Light grey background */
}


.view-checklist-view-image-icon {

  color: var(--primary-color);
  width: 30px;
  height: 30px;
}


.view-checklist-view-image-icon:hover {

  color: var(--secondary-color);
  width: 30px;
  height: 30px;
  
}

.view-checklist-view-image {
  width: 200px;
  height: 150px;
}

.view-checklist-create-checklist-button-contianer {

  background-color: var(--primary-color);
  color: white;
  display: flex;
  flex-direction: column;
  width: 20%;
  text-decoration: none;
  margin: auto;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  
  
  

}


.filters-container {
  
  display: flex;
  width: 80%;
  justify-content: space-between;
}


.view-checklists-columns-view-main-container {
  background-color: lightgrey;
  border-radius: 15px;
  margin: 20px 0 10px;
  padding: 10px 5px 20px 5px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  width: 90%;
  
}

.view-checklists-columns-checkboxes-view-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  
}

.view-checklists-columns-checkboxes-view-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/*------------------------------------------------------
              fill in Checklist
----------------------------------------------------*/



.fill-in-checklist-component-container {
  
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 5% 0;

  
}

.user-fill-in-checklist-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(245, 245, 245);
  border-radius: 60px;
  width: 70%;
  margin: auto;
  padding: 5%;
  
  
}

.user-fill-in-main-input-container {
  background-color: rgb(216, 216, 216);
  border-radius: 10px;
  width: 40%;
}

.user-fill-in-input-field {
  border: 1px solid var(--primary-color);
  width: 100%;
}

.user-fill-in-radio-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-fill-in-radio-container {
  
  width: 100%;
  display: flex;
  
}

.user-fillin-radio {

  display: flex;
  width: 100%;
  align-items: center;
}

.user-fill-in-input-photo-field {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  padding: 3px 5px;
  align-items: center;
}

.user-fill-in-input-photo-file {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.user-fill-in-input-photo-file-container {

  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  height: 25px;
  align-items: center;

}

.file-link {
  color: var(--primary-color);
}

.image-preview-container img{
  width: 500px;
  height: 350px;
}
/* User fill in checklist */



.user-fill-in-checklist-main-container {

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(189, 189, 189, 0.5); /* Transparent background */

}

.user-fill-in-checklist-container {

  background-color: white;
  width: 950px;
  height: 550px;
  
  border-radius: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  

}

.user-fill-in-elements-container {
  
  width: 95%;
  height: 95%;
  margin: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  
  

}

.user-fill-in-back-button-time-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: flex-end;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid var(--primary-color);
  
  
  
  
}

.user-fill-in-back-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  
}

.user-fill-date-container {

  display: flex;
  justify-content: flex-end;
  font-size: 13px;
  color: var(--primary-color);
  padding: 0 0 2% 0;
}


.user-fill-in-back-button {
  background-color: lightgrey;
  border: none;
  border-radius: 10px;
  height: 30px;
  width: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add drop shadow */

}

/**/

.user-fill-in-checklist-uid-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-fill-in-checklist-uid-container p {
  color: grey;
  font-size: 12px;
}

.user-fillin-checklist-uid-userid-container {

  
  display: flex;
  flex-direction: column;
  width: 30%;
  justify-content: space-between;
}

.user-fillin-checklist-id-container {

  
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.user-fill-in-submit-button {

  background-color: var(--primary-color);
  color : white;
  border: none;
  border-radius: 10px;
  height: 30px;
  width: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add drop shadow */

}

.user-fill-in-submit-clear-button-container {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 5% 0;
 align-items: center;

}

.user-fill-in-submit-back-button-container {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;

}

.user-fill-in-form-elements-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding: 2% 0;
}

.id-user-fill-in-input-field {
  display: none;
}

.columns-container {

  
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
  
 
}

/*------------ Userfill in radio button ------------------------*/

.user-fillin-checklist-risk-rating {
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid var(--secondary-color);
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 5px 0;
  justify-content: center;
  margin: auto;
}

.user-fillin-checklist-risk-rating-input {

  align-items: center;
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  
}

/*---------------------------------------------
      Toaster - Custom Checklist Container
  --------------------------------------------*/

.custom-field-main-component-container {

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Highest z-index */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(189, 189, 189, 0.5); /* Transparent background */

}

.custom-field-component-container {

  background-color: white;
  border-radius: 25px;
  height: 90%;
  width: 95%;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}

.category-secondary-color {
  color: var(--secondary-color);
  font-size: 20px;
}

.custom-field-container {
 
}

.create-questions-custom-field-container {

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 95%;
 
}

.create-questions-view-fillin--checklist-table-container {
 
    
  display: flex;
  flex-direction: column;
  
  width: 100%;
  margin: 0 auto;
  align-items: center;
}

.view-checklist-table-table-container {

  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: scroll;
}

.create-questions-view-checklist-table-table-container {
  
  width: 100%;
  overflow-x: scroll;
}


.view-fillin-checklist-table th,
.view-fillin-checklist-table td {

  width: 300px;
  padding: 8px;
  text-align: center;
  

}

.view-fillin-checklist-table th {
  background-color:#23406636;
  color: black;
  font-weight: 500;
  color: #1A3D6B;
}

.view-fillin-checklist-table tbody tr:hover {

  background-color: #f5f5f5;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
  
}

/**/
.view-fillin-checklist-table tbody tr:nth-child(even) td {
  
  background-color: #f0f0f0; /* Light grey background */
}


.custom-field-header-container {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid var(--primary-color);
}

.custom-field-heading-container {
  display: flex;
  flex-direction: column;
}

.custom-field-question-name-type-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
  padding: 5px 0;
  
}

.custom-field-question-name-container {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 49%;
}

.custom-field-quesion-name-input {
  width: 250px;
}

.custom-field-question-type-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 49%;
  padding: 20px 0;
}

.custom-field-select-container {
  display: flex;
  width: 50%;
  align-items: center;
  
}

.custom-checklist-section-rendering-container {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 1px solid var(--primary-color);
  width: 95%;
  height: 80px;
 
  overflow-y: scroll;
  margin: 10px auto;
  padding: 5px 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
}

.custom-checklist-rendering-header-container {
  
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.custom-checklist-rendering-error-select-question-name-type-container {
  text-align: center;
}

/*----------------------------------------------------------
        Create Questions Checkloist 
  ------------------------------------------------------*/

  .create-questions-checklist-custom-field-component-container {

    background-color: white;
    border-radius: 25px;
    height: 85%;
    width: 90%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
  
  }

  
  .create-questions-checklist-custom-field-question-name-type-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 5px 0;
    
  }

  .create-questions-checklist-custom-field-question-name-type-table-container {
    display: flex;
    justify-content: space-evenly;
  }

  .create-questions-checklist-custom-field-question-name-type-table-container {
    margin: 20px 0;
  }
  
  .predefined-sections-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .predefined-sections-table th,
  .predefined-sections-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .predefined-sections-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .predefined-sections-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .predefined-sections-table input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

 
  

/*------------ Custom Checklist - radio options -----------------*/

.custom-checklist-radio-options-main-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(240, 240, 240);
  border-radius: 15px;
  width: 90%;
  margin: 0 auto;
  
}

.custom-checklist-radio-options-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 90%;
  height: 200px;
  margin: 0 auto;
}

.custom-checklist-radio-options-body-container {
  
  display: flex;
  flex-direction: column;
  width: 100%;
}

.custom-checklist-radio-options-input-main-container {
  
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 5px 0;
  padding: 10px ;
  align-items: center;
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  background-color: white;
  
}

.custom-checklist-radio-options-input-container {

  width: 80%;
  display: flex;
}

.custom-checklist-radio-options-input {
  width: 80%;
}

.custom-checklist-radio-options-button-container {
  padding: 5px 0;
}

/*Checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  color: var(--secondary-color);
  margin: 0 auto;
  
}

/* ------------- Custom Checklist - Button Container ---------------*/

.custom-checklist-section-button-main-container {
  
  padding: 2% 0;
}

.custom-checklist-section-button-container {
  
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

}

.custom-checklist-section-confirm-button {

  background-color: var(--primary-color);
  border: none;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  height: 30px;
  width: 70px;
  border-radius: 5px;
  color: white;
}

.custom-checklist-section-cancel-button {

  
  border: none;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  height: 30px;
  width: 70px;
  border-radius: 5px;
  color: black;
}

/*--------------------------------------------------------------
                  Add Questions Create Checklist 
  ------------------------------------------------------------*/

  .add-question-custom-field-main-component-container {

    position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Highest z-index */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(189, 189, 189, 0.5); /* Transparent background */


  }

  .add-question-create-questions-checklist-custom-field-component-container {

    background-color: white;
    border-radius: 25px;
    height: 90%;
    width: 95%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
  
  }

  .add-question-view-checklist-component-container {
    
    width: 98%;
    height: 100%;
    overflow-x: scroll;
    margin: auto;
   
   
  
  }

  .add-question-view-checklist-header-container {
   
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-color);
    align-items: center;
    
  }

  .add-question-view-checklist-table-table-container {
    
    width: 100%;
  
    height: 300px;
    
  }
  

  .add-question-view-checklist-header-step-container {

    
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    
  

  }

  /**/

  .add-question-view-checklist-category-main-container {
    display: flex;
    justify-content: space-between;
    width: 95%;

    margin: 0 auto;
  }

  .duplicate-checklist-section-a-information-checklist-name-container-container {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
   
    
  }

  .duplicate-checklist-section-a-information-checklist-name-container {
 
  }
  
  .duplicate-fillin-select-checklist-section-a-information-checklist-name-container {
    
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .duplicated-select-checklist-name-main-container {
   
    display: flex;
    width: 100%;
  }

  .duplicate-fillin-select-checklist-section-a-information-checklist-name-name-container {

  }

  .add-question-view-checklist-category-container {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    
    
  }

  .save-questions-category-button-container {
    display: flex;
    background-color: var(--primary-color);
    color: white;
    border-radius: 10px;
    padding: 3px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    align-items: center;
    width: 120px;
    justify-content: space-between;
    cursor: pointer;
  }

  .save-questions-category-category-button-container {

    display: flex;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 10px;
    padding: 3px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    align-items: center;
    width: 120px;
    justify-content: space-between;
    cursor: pointer;

  }

  .add-question-view-checklist-category-input-and-view-container {
 
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }

  .add-question-view-checklist-category-list-main-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(235, 235, 235);
    border-radius: 10px;
    padding: 0px 5px 5px 10px;
    width: 98%;
    min-height: 50px;
    max-height: 250px;
    overflow-y: scroll;
    margin: auto 0;
    
    
  }

  .add-question-view-checklist-category-list-container {
    
  }

  .add-question-view-checklist-category-list-item-main-container {
    margin: 4px 0 ;
    border: 1px solid var(--primary-color);
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    
  }

  .add-question-view-checklist-category-list-item-container {

    border-radius: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 2px 15px;
    margin: 0 auto;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    
  }

  .add-question-view-checklist-category-list-item-edit-icon {
    width: 20px;
    height: 20px;
    color: var(--primary-color);
  }

  .add-question-view-checklist-category-list-item-edit-icon:hover {
    width: 20px;
    height: 20px;
    color: red;
  }

  .add-question-view-checklist-category-list-input {
    border: 1px solid var(--primary-color);
  }

  .add-question-view-checklist-icon-container {
    align-items: center;
    
    display: flex;
    width: 20%;
    justify-content: space-between;
  }

  .add-question-view-checklist-tick-icon-container {
    
  }

  .add-question-view-checklist-cancel-icon-container {
    
  }



 

  .add-question-view-checklist-category-question-type-main-container {
    
    display: flex;
    flex-direction: column;
    width: 30%;
    
  }

  .add-question-view-checklist-category-question-type-main-right-container {
    
    display: flex;
    flex-direction: row;
    width: 70%;
    
  } 

  .add-question-view-checklist-category-question-type-main-right-category-container {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
  
  }

  .add-question-view-checklist-input {
    margin: 0 10px 0 0;
  }
  
  .add-question-view-fillin--checklist-table-container {
    background-color: white;
    
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    
    width: 95%;
    margin: 10px auto;
    align-items: center;
  }

  .add-question-submit-clear-button-container {
    display: flex;
    width: 30%;
    margin: 0 auto;
    justify-content: space-between;
  }


  /***/

  .view-checklist-checklist-data-container {
    margin-top: 20px;
  }
  
  .view-data-checklist-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .view-checklist-submission-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .checklist-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .checklist-form h4 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
    cursor: pointer;
  }
  
  .form-label:hover {
    color: #007bff;
  }
  
  .form-field {
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .view-checklist-view-image {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .view-checklist-view-image-icon {
    font-size: 24px;
    color: #007bff;
  }

  /*-----------------------------------------------------------
                        Checklist Fillin 
  --------------------------------------------------------------*/
  .checklist-question-fill-in-checklist-container {
    background-color: white;
  width: 90%;
  height: 90%;
  border-radius: 20px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
    
    
  }

  .checklist-question-user-fill-in-checklist-main-container {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 189, 189, 0.5); /* Transparent background */
  }

  .checklist-question-toaster-user-fill-in-checklist-uid-container {
    
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    height: 400px;
    overflow-y: auto;
    width: 96%;
    margin: 0 auto;
   

  }

  .checklist-question-toaster-user-id-container {
    display: flex;
    
    width: 100%;
    justify-content: space-between;

  }

  .checklist-question-toaster-user-id-header-container {
    width: 100%;
    text-align: center;
  }

  .checklist-fillin-question-toaster-user-id-header-container {
    width: 95%;
    margin: 0 auto;
  }

  .checklist-fillin-question-toaster-user-id-save-header-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .checklist-fillin-question-toaster-user-id-save-header-icon-container {
    width: 10%;
  }

  .checklist-question-toaster-user-fill-in-checklist-element-category-save-container {
    display: flex;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
  }

  .checklist-fillin-question-toaster-user-id-save-header-icon {
    color: var(--primary-color);
    width: 30px;
    height: 30px;
  }

  .checklist-fillin-question-toaster-user-id-save-header-category-icon {
    color: var(--primary-color);
    width: 30px;
    height: 30px;
  }

  .checklist-fillin-question-toaster-user-id-save-header-category-icon :hover {
    color: var(--secondary-color);
    
  }

 

  .checklist-question-toaster-user-id-container-userdetails p {
    font-size: 13px;
    color: gray;
  }

  .checklist-question-toaster-user-fill-in-checklist-element-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin: 5px 0;
    border-radius: 10px;
    padding: 0 1%;
    
  }

  .checklist-question-toaster-user-fill-in-checklist-category-container {
    display: flex;
    flex-direction: row;
    
    margin: 5px 0;
    padding: 10px 5px;
    border-radius: 15px;
    width: 95%;
    margin: 5px auto;
   
  }



  .checklist-question-toaster-user-fill-in-checklist-question-container {
    width: 47%;;
    background-color: rgb(245, 245, 245);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; /* Adjust this to wrap questions to the next line if needed */
    padding: 0 5px;
    margin: 15px 0;
    
  }

  .question-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust spacing between elements */
    width: 100%;

  }
  .checklist-question-toaster-user-fill-in-checklist-question-element-container {
    
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px auto;
    
  }
  
  .checklist-questions-rendering-question-name-and-save-icon-rendering-container {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
  }

  .checklist-fillin-question-name-and-save-icon-rendering-save-category-icon {
    width: 20px;
    height: 20px;
    color: var(--primary-color);
  }

  .checklist-fillin-button-question-name-and-save-icon-rendering-save-category-icon {
    width: 15px;
    height: 15px;
    color: white;
    
  }

  .checklist-fillin-question-name-and-save-icon-rendering-save-category-icon:hover {
    color: var(--secondary-color);
  }

  .classNamechecklist-question-toaster-user-fill-in-checklist-question-element-options-container {
    width: 90%;
   
    
  }

  .checklist-question-toaster-image-delete-button-container {

    display: flex;
    background-color: var(--secondary-color);
    margin: 10px 0;
    width: 70%;
    border-radius: 10px;
    justify-content: space-between;
    padding: 5px 10px;
    align-items: center;

  }

  .checklist-question-toaster-image-icon {
    width: 25px;
    height: 25px;
    color: white;
  }

  .delete-image-button {
    border-radius: 15px;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);

  }

  /**/
.file-upload-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  
}

  .classNamechecklist-question-toaster-user-fill-in-checklist-question-options {
 
    display: flex;
    width: 100%;
    flex-direction: column;
   
  }

  .label-input-user-fill-in {
    display: flex;
    align-items: center;
  }

  .checklist-question-toaster-button-cancel-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    
    margin: 0 auto;
    justify-content: space-evenly;
  }

  /*----------------------------------------------
                Custom Field 
  -------------------------------------------*/

  .custom-field-custom-toaster-component-container {

    background-color: white;
    border-radius: 25px;
    height: 70%;
    width: 70%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  
  }

  /*---------------------------------------------------------------------
                        Add Questions Create Checklist 
  ----------------------------------------------------------------------*/

  .add-quesiton-side-menu-logo {
    width: 150px;
    height: 70px;
  }

  .create-questions-form-name-input {
    border: 1px solid var(--primary-color);
    width: 70%;
  }

  .create-questions-form-question-input-column-container {
    
    width: 200px;
    display: flex;
    flex-direction: column;
  }

  .create-questions-form-question-number-input-column {
    display: flex;
    margin: 0 auto;
    width: 50%;
    border: 1px solid lightgray;
  }

  .add-questions-create-checklist-question-name-column {
    width: 200px;
  }

  .create-questions-view-fillin-checklist-table {
    
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 1rem;
    text-align: left;
    table-layout: fixed; /* Prevents table overflow by fixing the layout */
}

.create-questions-view-fillin-checklist-table th, 
.create-questions-view-fillin-checklist-table td {
    padding: 10px;
    border: 1px solid #ddd;
    overflow: hidden; /* Ensures content does not overflow */
    text-overflow: ellipsis; /* Adds ellipsis (...) for overflowing text */
    white-space: nowrap; /* Prevents text from wrapping to multiple lines */
    font-size: 14px;
    
}

.create-questions-view-fillin-checklist-table th.table-header {
    background-color: #f4f4f4;
    font-weight: bold;
    text-align: center;
}

.create-questions-view-fillin-checklist-table tbody .view-table-body {
    background-color: #fff;
    
}

.create-questions-view-fillin-checklist-table tr:nth-child(even) {
    background-color: #f9f9f9;
    
}

.create-questions-view-fillin-checklist-table select,
.create-questions-view-fillin-checklist-table input[type="text"] {
    width: 100%; /* Ensures inputs take the full width of the cell */
    padding: 8px;
    margin: 0; /* Removes margin so input fits inside the cell */
    box-sizing: border-box; /* Ensures padding is included in the total width */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 11px;
}

.create-questions-view-fillin-checklist-table td {
    vertical-align: middle;
}

.fillin-checklist-submittion-icon {
    cursor: pointer;
    font-size: 1.5rem;
    color: #ff4d4d;
}

.fillin-checklist-submittion-icon:hover {
    color: #ff0000;
}

.create-questions-view-fillin-checklist-table td[colspan] {
    text-align: center;
    font-style: italic;
    color: #888;
}

/* New styles for the delete column */
.create-questions-view-fillin-checklist-table .delete-header {
  width: 40px;
  padding: 0;
}

.create-questions-view-fillin-checklist-table .delete-cell {
  width: 40px;
  padding: 0;
  text-align: center;
}

.create-questions-view-fillin-checklist-table .remove-row-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-row-add-option-container {
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-between;
}

.pagination {
  
  display: flex;
  background-color: lightgray;
  width: 10%;
  border-radius: 5px;

}

.page-item {
  background-color: lightblue;
  
}

/* Tablets (Portrait) */
@media (min-width: 600px) and (max-width: 800px) {

  .checklist-question-toaster-user-fill-in-checklist-uid-container {
    
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    height: 400px;
    overflow-y: auto;
    width: 96%;
    margin: 0 auto;
    
   

  }

  .checklist-question-toaster-user-fill-in-checklist-element-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin: 5px 0;
    border-radius: 10px;
    padding: 0 1%;
    
    
  }

  .checklist-question-toaster-user-fill-in-checklist-category-container {
    display: flex;
    flex-direction: row;
    
    margin: 5px 0;
    padding: 10px 5px;
    border-radius: 15px;
    width: 95%;
    margin: 5px auto;
    
   
  }

  .question-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust spacing between elements */
    width: 100%;
   

  }

  .checklist-question-toaster-user-fill-in-checklist-question-container {
    width: 80%;;
    background-color: rgb(245, 245, 245);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; /* Adjust this to wrap questions to the next line if needed */
    padding: 0 5px;
    margin: 15px 0;
    
  }


}

/**/
.file-upload-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  
}

/* Tablets (Landscape) and Low-Resolution Laptops/Desktops */
@media (min-width: 801px) and (max-width: 1024px) {

  .checklist-question-toaster-user-fill-in-checklist-uid-container {
    
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    height: 400px;
    overflow-y: auto;
    width: 96%;
    margin: 0 auto;
    
   

  }

  .checklist-question-toaster-user-fill-in-checklist-element-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin: 5px 0;
    border-radius: 10px;
    padding: 0 1%;
    
    
  }

  .checklist-question-toaster-user-fill-in-checklist-category-container {
    display: flex;
    flex-direction: row;
    
    margin: 5px 0;
    padding: 10px 5px;
    border-radius: 15px;
    width: 95%;
    margin: 5px auto;
    
   
  }

  .question-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust spacing between elements */
    width: 100%;
   

  }

  .checklist-question-toaster-user-fill-in-checklist-question-container {
    width: 80%;;
    background-color: rgb(245, 245, 245);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap; /* Adjust this to wrap questions to the next line if needed */
    padding: 0 5px;
    margin: 15px 0;
    
  }

  .link {
    text-decoration: none;
  }

  .application-feature-container{
    margin: 10px ;
  }
  .checklist-component-container {
    
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    justify-content: space-evenly;
  
    
  }

  .checklist-text-container {

    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding: 2% 0;

  }

  .checklist-feature-main-container {

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    height: 80%;
    overflow-y: scroll;
    margin: 5px auto;
  
  }


}

/*------------------------------------
            Create Checklsit
  --------------------------------*/

  .create-checklist-section-a-information-main-container {

  }

  .create-checklist-section-container-main-container {
    display: flex;
    border-radius: 20px;
    margin: 20px auto;
    width: 95%;
    border: 1px solid var(--primary-color);
    background-color: rgb(231, 231, 231);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    padding: 5px;
  }

  .create-checklist-section-container {
    
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin: 0 auto;
  }

  .create-checklist-section-a-information-container {
  
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
  }

  .create-checklist-section-a-information-container-container {
    
    width: 97%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .create-checklist-questions-form-name-input {
    border: 1px solid var(--primary-color);
  }

  .create-checklist-section-a-information-checklist-name-container {
   
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
    align-items: center;
  }

  .create-checklist-section-a-information-checklist-category-container {
    
    display: flex;
    flex-direction: column;
    width: 40%;
    
    align-items: center;
  }

  .create-checklist-section-a-information-checklist-saved-category-container {
    background-color: rgb(224, 224, 224);
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    overflow-y: scroll;
    height: 250px;
  }

  .create-checklist-section-a-information-checklist-saved-category-list-container {
    
    width: 95%;
    
  }

  .create-checklist-section-b-information-checklist-category-input-button-container {
 
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
  }

  /*--------------------------------
              Edit Checklist 
  ---------------------------------*/
  .edit-checklist-section-a-information-checklist-name-container {
    
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: space-between;
    
  }

  
/*------------------------------------
                Pagination
  ----------------------------*/

  .pagination {
    display: flex;
    width: 100%;
    list-style: none;
    padding: 10px 0;
    margin: 2px 0;
    gap: 3px ;
    justify-content: center;
    
   
  }
  
  .page-item {
    margin: 0;
  }
  
  .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    height: 2.5rem;
    padding: 5px 0;
    border-radius: 5px;
    background-color: #f3f4f6;
    color: #374151;
    border: 1px solid var(--primary-color);
    transition: all 0.2s ease;
   
  }
  
  .page-link:hover {
    background-color: #e5e7eb;
    color: #111827;
  }
  
  .page-item.active .page-link {
    background-color: var(--primary-color);
    color: white;
  }
  
  .page-item.active .page-link:hover {
    background-color: var(--secondary-color);
  }
  
  /* Optional: Disabled state styles */
  .page-item.disabled .page-link {
    background-color: #e5e7eb;
    color: #9ca3af;
    cursor: not-allowed;
  }
  
 