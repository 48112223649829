/* CSS file  - Compliance tracker */

:root {

    --primary-color: #1A3D6B;
    --secondary-color:#0088CB;
  
  }
  
  h1, h2, h3, h4, h5, h6 {

    color: var(--primary-color);
    
  }
  
  body {
  
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
  }
  .confirm-button-response {
    background-color: var(--secondary-color);
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    color: white;
    margin: 10px 0;
  
  }
  
  .confirm-button {
    background-color: var(--primary-color);
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    color: white;
    margin: 10px 0;
  }
  
  .reject-button {
    background-color: red;
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    color: white;
    margin: 10px 0;
  }
  
  .cancel-button {
    background-color: lightgray;
    height: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    color: black;
    margin: 10px 0;
  }
   
  
  .error-text-container {
    
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 0 0;
   
    
  }
  
  .error-text {
    color: red;
    background-color: rgba(236, 236, 236, 0.733);
    border-radius: 10px;
    border: solid 1px red;
    padding: 5px;
    margin: 10px auto;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    width: 90%;
  }

  /*-------------------------------------------------------------
                    COmplaince Tracker - People Management 
------------------------------------------------------------------*/

/*-------------- Create Employee -----------------*/

.compliance-tracker-company-information-main-container {
   
    display: flex;

}

.compliance-tracker-create-employee-selecetd-company-department-conainer {
  background-color: rgb(238, 238, 238);
  border: 1px solid green;
  display: flex;
  justify-content: space-evenly;
  color: green;
  border-radius: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  align-items: center;
}

/*--------------------------------------------------------
                  Consultant Fill In 
---------------------------------------------------------*/

.manual-upload-main-container {

  background-color: rgb(235, 235, 235);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary-color);
  border-radius: 15px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  padding: 10px ;

}

/*------------------ Tables UI ------------*/
.template-table-manual-upload thead {
  background-color: var(--secondary-color);
  color: black;
  text-align: center;
  align-items: center;
}

.template-table-manual-upload thead th {
  padding: 12px 8px;
  text-align: left;
  font-weight: 500;
}

.template-table-manual-upload tbody tr {
  transition: all 0.3s ease;
  color: black;
}

.template-table-manual-upload tbody tr:hover {
  background-color: lightgrey;
  
}

.template-table-manual-upload tbody td {
  padding: 10px 8px;

}

/*------------------ Image preview -------------------*/
.full-screen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
}

.full-screen-image-container {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: default;
}

.close-image-modal-btn {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 40px;
  cursor: pointer;
  padding: 0 10px;
}

.close-image-modal-btn:hover {
  color: #ff4444;
}