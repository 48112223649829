/* CSS for Toasters */

:root {
    --primary-color: #1A3D6B;
    --secondary-color: #0088CB;
}

h1, h2, h3, h4, h5, h6 {
    
    color: var(--primary-color);
    
  }

/*------------------------------
        Login Successful
------------------------------*/

.login-toaster-component-container {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Highest z-index */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 189, 189, 0.5); /* Transparent background */

}

.login-toaster-container {

    background-color: white;
    border-radius: 25px;
    height: 450px;
    width: 450px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}

.login-tick {
    color: lime;
    width: 150px;
    height: 150px;
}

.logins-text-container  {
    font-size: 20px;
    text-align: center;
    font-weight:600;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0 ;
    
}

.logins-text-container h1 {
    font-size: 35px;
    text-align: center;
    font-weight:600;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0 ;
}

.logins-text-container p {
    text-align: center;
    color: black;
    font-size: 18px;
    width: 90%;
    margin: 0 auto;
    padding: 10px 0 ;
    font-weight: lighter;
}

/* Add this for the loading circle */
@keyframes spinner {
    to { transform: rotate(360deg); }
  }
  
  .loading-circle {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-top-color: var(--secondary-color);
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }

  /*------------- Mobile login toaster ---------*/
  @media only screen and (max-width: 450px) {

    .login-toaster-component-container {

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999; /* Highest z-index */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(153, 153, 153, 0.911); /* Transparent background */
    
    }
    
    .login-toaster-container {
    
        background-color: white;
        border-radius: 25px;
        height: 350px;
        width: 350px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
        padding: 2% 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    
    }
    
    .login-tick {
        color: lime;
        width: 100px;
        height: 100px;
    }
    
    .logins-text-container h1 {
        font-size: 35px;
        text-align: center;
        font-weight:600;
        width: 90%;
        margin: 0 auto;
        padding: 10px 0 ;
    }
    
    .logins-text-container p {
        text-align: center;
        color: black;
        font-size: 18px;
        width: 90%;
        margin: 0 auto;
        padding: 10px 0 ;
    }
    
    /* Add this for the loading circle */
    @keyframes spinner {
        to { transform: rotate(360deg); }
      }
      
      .loading-circle {
        width: 24px;
        height: 24px;
        border: 3px solid rgba(0, 0, 0, 0.3);
        border-top-color: var(--secondary-color);
        border-radius: 50%;
        animation: spinner 1s linear infinite;
      }

  


  }

/* ------------------------------------
        Success */

.success-tick {
    color: rgb(0, 182, 0);
    width: 200px;
    height: 200px;
}

.success-checklist-created-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Highest z-index */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 189, 189, 0.5); /* Transparent background */
}

.success-checklist-created-container {
    background-color: rgb(235, 235, 235);
    width: 500px;
    height: 500px;
    border-radius: 20px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/*----------------------------------------------------------
        Create Checklist - Added Checklist Section
-----------------------------------------------------------*/

.added-checklist-section-component-container {

    /*background-color: pink; */
    width: 100%;
    height: 100px;
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Highest z-index */
    display: flex;
    justify-content: center;

}

.added-checklist-section-container {

    background-color: rgb(235, 235, 235);
    width: 300px;
    height: 70px;
    border-radius: 20px;
    margin: 0 auto;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.added-checklist-section-tick-container {

    margin: auto 0;
}

.added-checklist-section-tick {

    color: var(--secondary-color);
    width: 30px;
    height: 30px;
}

.removed-checklist-section-tick {

    color: red;
    width: 30px;
    height: 30px;
}


.added-checklist-section-text-container {

    margin: auto 0;
    font-weight: 550;
    font-size: 17px;

}

/*remove */

.remove-checklist-section-tick {
    color: red;
    width: 30px;
    height: 30px;
}

/**/

.added-checklist-section-error {
    color: red;
    width: 30px;
    height: 30px;
    
}

/*---------------------------------------------
        Confirm Delete Table Toaster 
--------------------------------------------*/

.confirm-delete-toaster-component-container {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Highest z-index */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 189, 189, 0.5); /* Transparent background */

}

.confirm-delete-toaster-container {

    background-color: white;
    border-radius: 25px;
    height: 500px;
    width: 600px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid red;

}

.confirm-delete-icon {
    color: red;
    width: 150px;
    height: 150px;
}

.confirm-delete-text-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
}

.confirm-delete-text-container h2{
    color: red;
}

.error-text-text p  {
    color: red;
}

.confirm-delete-text-container p{
    line-height: 30px;
   
}

.confirm-delete-button-container-container {

    display: flex;
    width: 50%;
    height: 50px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-evenly;
}

.confirm-delete-button-yes {

    border: none;
    background-color: lightgray;
    width: 50px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
}

.confirm-delete-button-no {

    border: none;
    background-color: lightgray;
    width: 50px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */

}

/* Add this for the loading circle */
@keyframes spinner {
    to { transform: rotate(360deg); }
  }
  
  .loading-circle {
    width: 24px;
    height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.3);
    border-top-color: var(--secondary-color);
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }

  /*----------------------------------------
        Delete Confirm MS Power Bi Link delete
    -------------------------------------------- */


  .delete-ms-power-point-link-button-container {
    display: flex;
    margin: 0 auto;
    width: 50%;
    justify-content: space-evenly;
  }

  .confirm-delete-Ms-powerpoint-toaster-container {

    background-color: white;
    border-radius: 25px;
    height: 450px;
    width: 450px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 2px solid red;

}

.confirm-delete-Ms-powerpoint-toaster-icon {
    color: red;
    width: 150px;
    height: 150px;
}

