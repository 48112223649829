/* CSS FIle - SHE Packs 

This file is used as the CSS for the SHE Pack componets
*/

:root {

    --primary-color: #1A3D6B;
    --secondary-color:#0088CB;
  
  }

  .secondary-color-text {
    color: var(--secondary-color);
  }

/*------------------------------------------------
                    Errors 
--------------------------------------------------*/

.error-text-main-container {
  display: flex;
  background-color: white;
  border: 1px solid red;
  border-radius: 15px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  width: 90%;
  margin: 0 auto;
  padding: 0 10px;
  color: red;
}

/*-----------------------------------------------------------
                    Input field
-----------------------------------------------------------*/

.create-she-pack-input-field {
    border: 1px solid var(--primary-color);
}

/*--------------------------------------------------------------
                    Create SHE Pack 
  ------------------------------------------------------------*/

  .create-she-pack-main-component-container {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Highest z-index */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 189, 189, 0.5); /* Transparent background */

  }

  .she-pack-custom-field-component-container {

    background-color: white;
    border-radius: 25px;
    height: 70%;
    width: 50%;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  
  }

  .create-she-pack-custom-field-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95%;
   
  }

  .create-she-pack-view-she-pack-component-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 98%;
    height: 100%;
    overflow-x: scroll;
    margin: auto;
  
  }

  .create-she-pack-view-she-pack-header-container {
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary-color);
    align-items: center;
  }

  .create-she-pack-view-she-pack-header-step-container {
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .create-she-pack-name-main-container {

    display: flex;
    width: 95%;
    margin: 0 auto;
  }

  .create-she-pack-name-container {

    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
  }

  /*----------------------------------------------
                Create SHE Pack Document
  ------------------------------------------------*/


  /*-------------------- Question bank table --------------------*/
  .create-she-pack-document-quesiton-bank-table-total-score-main-container {
    
    width: 100%;
  }

  .create-she-pack-document-quesiton-bank-table-total-score-container {
    border: 1px solid var(--secondary-color);
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: space-between;
    padding: 0 10px;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  }

    /*----------------------------------------------
                Fill In SHE Pack Document
  ------------------------------------------------*/

  .she-pack-fill-in-question-score-header-main-container {
    
    display: flex;
    justify-content: space-between;

  }

  .she-pack-fill-in-document-score-and-percentage-header-text-container {
    background-color: rgb(236, 236, 236);
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 35%;
    justify-content: space-evenly;
   
  }

  .she-pack-fill-in-score-document-container {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }


  .she-pack-fill-in-question-score-main-container {

    background-color: white;
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 15px 0;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    padding: 0 10px;

  }

  /**/
  .she-pack-fill-in-question-score-container {
    
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: space-between;

  }

  /*----------------------------------------------
                      Template
  ------------------------------------------------*/

  .template-question-fill-in-checklist-container {
    background-color: white;
    width: 95%;
    height: 95%;
    border-radius: 20px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

  }

  .Template-fill-in-header-main-container {
    
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 95%;
    overflow-y: auto;
    width: 96%;
    margin: 0 auto;
    
   

  }

  .template-fill-in-elements-container {
  
    width: 98%;
    height: 98%;
    margin: auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    
    
  
  }


  .Template-fill-in-header-text-and-search-main-container {
    
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .Template-fill-in-header-text-main-container {
    
    display: flex;
    flex-direction: column;
    width: 40%;
  }

  .Template-fill-in-header-search-main-container {
    
    display: flex;
    flex-direction: column;
    width: 30%;
  }

  .Template-fill-in-header-search-search-bar-container {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-between;
  }

  .template-fill-in-search-bar {
    border: 1px solid var(--primary-color);
  }


  .template-questions-user-fill-in-main-container {
    
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    height: 400px;
    overflow-y: auto;
    width: 96%;
    margin: 0 auto;
   

  }

  .template-questions-user-fill-in-main-container {
    display: flex;
    overflow: scroll;
    width: 100%;
  }

  .template-table-main-container {
    display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: var(--primary-color);
  
  
  }

  .template-table-main-header-container {
    background-color: #1a3d6b4d;
    
  padding: 10px;
  border-bottom: 1px solid #ccc;
  }

  .template-table-header-container {
    display: flex;
  justify-content: space-between;
  }

  .template-table-header {
  
  text-align: center;
  font-weight: bold;
  min-width: 230px;
    
  }

  .template-table-body {
    display: flex;
  flex-direction: column;
  color: black;
  }

  .template-table-body-row-main-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #eee;
  }

  .template-table-body-row-container {
    display: flex;
  justify-content: space-between;
  padding: 10px;
  }

  .template-table-body-row {
    background-color: #f5f5f5;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  cursor: pointer;
  
  }

  .template-questions-fill-in-main-container {

    
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
    background-color: lightgrey;

  }

  .template-questions-fill-in-container {

    background-color: rgb(241, 241, 241);
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin: 5px 0;
    border-radius: 10px;
    padding: 0 1%;
    
    
  }

  .template-table-body-row-td {
  
    font-size: 13px;
    display: flex;
    min-width: 230px;
    max-width: 230px;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    
  }

  .template-table-body-row-container {
   
    border-radius: 6px;
    border:1px solid var(--primary-color);
    width: 100%;
    margin: 5px 0;
  }

  .template-questions-fill-in-individal-questions-main-container {
    display: flex;
    flex-direction: column;
    
    width: 99%;
  }

  .template-questions-fill-in-individal-questions-container {
    display: flex;
  }

  .template-questions-fill-in-individal-questions-container-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
    border-radius: 15px;
    border: 1px solid var(--primary-color);
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  }

  /*Section - Errros and save Button */
  .template-questions-fill-in-sections-errors-and-save-button-main-container{
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    align-items: center;
  }
  
  /* Section - question name and question score  */
  .template-questions-fill-in-sections-question-name-and-question-score-main-container {
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .template-questions-fill-in-sections-question-name-main-container {
    
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .template-questions-fill-in-sections-question-score-main-container {
   
    display: flex;
    flex-direction: column;
    width: 20%;
  }

  /*Section - Additonal Comment*/
  .template-questions-fill-in-sections-additional-comment-main-main-container {
   
    display: flex;
    flex-direction: column;
    width: 100%;
    
  }

  .template-questions-fill-in-sections-additional-comment-main-container {
   
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  .template-questions-fill-in-sections-additional-comment-container {
 
    display: flex;
    width: 100%;
    padding: 0 5px;
    
  
  }

  .template-questions-fill-in-sections-additional-comment-additional-information-main-container {
    
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;
    margin: auto;
    border-top: 1px solid var(--secondary-color);
  }

  .Template-file-upload-main-container {
    background-color: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
     
  }

  /*-----------------------------------------------------------
                      Template view
  -------------------------------------------------------------*/
  .template-view-questions-fill-in-container  {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
 
  }

  .template-view-questions-fill-in-container {

   
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    margin: 5px 0;
    border-radius: 10px;
    padding: 0 1%;
    
    
  }

  .template-questions-view-submissiosn-header-main-container {
    background-color: lime;
    display: flex;
    flex-direction: row;

  }

  .questions-table-wrapper {
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
  }
  
  .questions-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
  }
  
  .questions-table th,
  .questions-table td {
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .questions-table th {
    background-color: #f5f5f5;
    font-weight: 600;
  }
  
  .questions-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .score-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .score-container select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 100px;
  }
  
  .error-border {
    border-color: #ff4444;
  }
  
  .additional-info-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .comment-section {
    width: 100%;
  }
  
  .comment-section textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
  }
  
  .char-limit {
    font-size: 0.8rem;
    color: #666;
    margin-top: 4px;
  }
  
  .file-upload-section {
    width: 100%;
  }
  
  .file-input {
    width: 100%;
    padding: 8px;
  }
  
  .image-controls {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
  }
  
  .preview-icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
  }
  
  .delete-button {
    background: none;
    border: none;
    color: #ff4444;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .template-question-view-submissions-checklist-container {
    background-color: orange;
    width: 95%;
    height: 95%;
    border-radius: 20px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow-y: scroll;

  }

  .Template-view-submissions-header-text-main-container {
    
    display: flex;
    background-color: #ff4444;
    width: 95%;
  }

  .template-view-fill-in-elements-container {
    
    display: flex;
    width: 100%;
    height: 90%;
    padding: 0 20px;
    justify-content: space-evenly;
    flex-direction: column;
  }


  .template-question-fill-in-checklist-container-view {
    background-color: white;
    width: 95vw;
    height: 95vh;
    border-radius: 20px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

  }

  .question-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px auto;
    overflow: hidden;
    text-align: center;
  }
  
  .question-table thead th {
    background-color: #23406636; /* Light blue-gray background */
    color: #1A3D6B; /* Darker blue for text */
    font-weight: 500;
    padding: 8px;
    text-align: center;
  }
  
  .question-table tbody td {
    padding: 8px;
    text-align: center;
    width: 300px; /* Maintain consistent width as in the old table */
  }
  
  .question-table tbody tr:hover {
    background-color: #f5f5f5;
    border: 1px solid var(--primary-color); /* Consistent hover effect */
    border-radius: 10px;
    cursor: pointer;
  }
  
  .question-table tbody tr:nth-child(even) td {
    background-color: #f0f0f0; /* Light grey background for even rows */
  }
  
  .secondary-color-text {
    color: var(--secondary-color);
    font-style: italic;
  }
  
  .score-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    color: #1A3D6B;
    background-color: #e9f3ff; /* Light blue for score container */
  }
  
  .additional-info-container {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
  }
  
  .comment-text {
    color: #495057;
    font-size: 0.95rem;
    margin: 0;
  }
  
  
  .Total_document_score_div {
    background-color: lightblue;
    display: flex;
    font-weight: bold;
   
  }
  
  .she-file-submisisons-template-table-body-row-container {
    display: flex;
  justify-content: space-between;
  padding: 10px;
    border-radius: 6px;
    width: 100%;
    margin: 5px 0;
  }

  .she-file-submisisons-total-template-table-body-row-container {
    display: flex;
  justify-content: space-between;
  padding: 10px;
    border-radius: 6px;
    width: 100%;
    margin: 5px 0;
    font-weight: bold;
    background-color: lightgray;
  }

  /*-------------------- Duplicate SHE Template -------------------*/

  .duplicate-section-information-template-detail-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .duplicate-section-information-template-name-select-template-detail-main-container {
    background-color: rgb(238, 238, 238);
    display: flex;
    margin: 5px 0;
    padding: 10px;
    border-radius: 30px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
  }

  .duplicate-template-loaded-confirmation {
    display: flex;
    border: 1px solid green;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
    align-items: center;
    justify-content: space-evenly;
    color: green;
  }

  .duplicate-template-selected-template-confirmation-icon {
    color: green;
  }

  /*--------------------------------------------
              QA Template Submissions 
  ----------------------------------------------*/

  .success-message-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    background-color: rgb(240, 240, 240);
    border-radius: 10px;
    border: 1px solid green;
    color: green;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
    text-align: center;
  }

  /* QA Template Reject */

  .qa-reject-main-container {
    background-color: white;
    width: 50%;
    height: 80%;
    border-radius: 20px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); 
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

  }
  
  .template-table-body-row-container-text {
    text-align: center;
    margin: auto;
  }

  .qa-reject-text-area-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: pink;
    overflow: scroll;
  }

  .qa-reject-text-area{
    min-height: 200px;
  }


  /**/
  
  .template-table thead {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    align-items: center;
  }
  
  .template-table thead th {
    padding: 12px 8px;
    text-align: left;
    font-weight: 500;
  }
  
  .template-table tbody tr {
    transition: all 0.3s ease;
    color: black;
  }
  
  .template-table tbody tr:hover {
    background-color: lightgrey;
    
  }
  
  .template-table tbody td {
    padding: 10px 8px;
  
  }

  .template-save-icon {
    color: var(--primary-color);
  }

  .template-save-icon:hover {
    color: var(--secondary-color);
    
  }

  .template-questions-user-fill-in-main-container-consultant {
    display: flex;
    overflow-x: scroll;
    width: 100%;
 
  }

  .template-table-main-container-consultant {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 4px;
    color: var(--primary-color);

  }

  

  

  
  